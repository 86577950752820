import { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { SchedulerData } from "../components/scheduler/types";
import replaceItem from "../utilities/replaceItem";
import dayjs from "dayjs";
import { useSubscribe } from "../hooks/useSubscribe";
import { usePublish } from "../hooks/usePublish";
import { Appointment } from "../types/bookings";
import uniqueArray from "../utilities/uniqueArray";
import removeFalsyValues from "../utilities/removeFalsyValues";
import { defaultSchedulerFilters, SchedulerFilter } from "./schedulerFilter";
import { Gender } from "../types/common";
import { Specialty } from "../types/clinicalPractitioners";
import { ConsultationType } from "../types/consultations";
import { AppointmentStatus } from "../types/appointments";
import { INPUT_TIME_INVALID_VALUE } from "../constants/appointments";
import { splitByCapitalLetters } from "../utilities/splitByCapitalLetters";

export const APT_DISPLAY_LIMIT = 300;

interface FormattedAppointment {
  id: number;
  startDate: string;
  endDate: string;
  title: string;
  name: string;
  status: Appointment["status"];
  booking: Appointment["booking"];
  message: Appointment["message"];
  gender: string;
}

interface Filter {
  consultationType?: ConsultationType[];
  bookingConsultationType?: ConsultationType[];
  start: string;
  end: string;
  status: AppointmentStatus[];
  gender?: Gender;
  specialties?: Specialty;
  patientName?: string;
  patientDateOfBirth?: string;
  gMC?: boolean;
  iMC?: boolean;
  resourcePoolId?: number;
  clinicalPractitionerId?: number[];
  bookedByClientIds?: number[];
  limit: number;
}

interface Results {
  loading: boolean;
  error: string;
  hasError: boolean;
  data: SchedulerData;
}

const defaultStatus: AppointmentStatus[] = [
  "Available",
  "OnHold",
  "Completed",
  "Booked",
];

function getAppointmentName(aptDetails: Appointment) {
  const { status, clinicalService, clinicalPractitioner } = aptDetails;

  if (status === "Nonworking") return "Break";

  if (status === "Completed" || status === "Booked") {
    if (aptDetails.consultationType === "MessageDoctor") {
      return aptDetails.consultationType;
    }

    if (!aptDetails.booking) {
      return "details unknown";
    }

    const { patientName, clientName, consultationType } = aptDetails.booking;

    return `${consultationType} - ${clientName} - ${patientName}`;
  }

  if (status === "Available") {
    if (clinicalService?.name) {
      return clinicalService.name;
    } else {
      const specialty = clinicalPractitioner.specialties[0];
      return splitByCapitalLetters(specialty);
    }
  }

  return splitByCapitalLetters(status);
}

function prepareData(data: Appointment[]) {
  const formattedData = formatData(data);
  const sortedData = sortData(formattedData);

  return sortedData;
}

function formatData(data: Appointment[]): FormattedAppointment[] {
  return data.map(apt => {
    const title = getAppointmentName(apt);
    const { consultationType, clinicalPractitioner, clinicalService } = apt;
    const { name: cpName, gender, specialties } = clinicalPractitioner;

    return {
      startDate: apt.start,
      endDate: apt.end,
      title,
      name: cpName,
      status: apt.status,
      id: apt.id,
      booking: apt.booking,
      message: apt.message,
      gender,
      consultationType,
      specialty: specialties[0],
      clinicalServiceName: clinicalService?.name
    };
  });
}

function sortData(data: SchedulerData): SchedulerData {
  return data.sort((a, b) => {
    if (dayjs(a.startDate).isAfter(b.startDate)) {
      return 1;
    }

    if (dayjs(a.startDate).isBefore(b.startDate)) {
      return -1;
    }

    return 0;
  });
}

function getValidFilter(filter: Partial<Filter>) {
  const withEmptyFiltersRemoved: Record<string, unknown> = {};

  Object.keys(filter).forEach(key => {
    const val = filter[key as keyof Filter];

    if (val) {
      withEmptyFiltersRemoved[key] = val;
    }
  });

  return JSON.stringify(withEmptyFiltersRemoved);
}

interface DateFilterParams {
  date: Date;
  startTime?: string;
  endTime?: string;
}

function getDateFilters(params: DateFilterParams) {
  const { date, startTime = "00:00", endTime = "23:59" } = params;

  const formattedStartTime =
    startTime !== INPUT_TIME_INVALID_VALUE ? startTime : "00:00";
  const formattedEndTime =
    endTime !== INPUT_TIME_INVALID_VALUE ? endTime : "23:59";
  const chosenDate = dayjs(date).format("YYYY-MM-DD");

  const chosenStartTime = dayjs(`${chosenDate}T${formattedStartTime}`).format();
  const chosenEndTime = dayjs(`${chosenDate}T${formattedEndTime}`).format();

  return [chosenStartTime, chosenEndTime];
}

const mergeUpdatedAppointments = (
  schedulerData: Appointment[],
  appointmentsToUpdate: Appointment[]
) => appointmentsToUpdate.reduce(replaceItem, schedulerData);

const getNewAptsToDisplay = (
  schedulerData: Appointment[],
  appointmentsToAdd: Appointment[],
  startTime: string
) => {
  const allCpNames = schedulerData.map(slot => slot.clinicalPractitioner.name);
  const uniqueCpNames = uniqueArray(allCpNames);

  const filteredApts = appointmentsToAdd.filter(apt => {
    const isSameDay = dayjs(startTime).isSame(apt.start, "day");
    const aptCpName = apt.clinicalPractitioner.name;
    const isValidCP = uniqueCpNames.includes(aptCpName);

    return isSameDay && isValidCP;
  });

  return filteredApts;
};

export function areFiltersInInitialState(filters: Record<string, any>) {
  const { date, ...filtersWithoutDate } = filters;
  const validFilters = removeFalsyValues(filtersWithoutDate);

  return (
    JSON.stringify(validFilters) === JSON.stringify(defaultSchedulerFilters)
  );
}

function useListAppointments(filter: SchedulerFilter): Results {
  const [data, setData] = useState<Appointment[]>([]);
  const [hasReceivedInitialResponse, setHasInitialResponse] = useState(false);
  const [hasError, setError] = useState(false);
  const requestId = useRef<string | null>(null);
  const publish = usePublish();
  const filtersAreEmpty = areFiltersInInitialState(filter);

  const formattedData = prepareData(data);
  const [start, end] = getDateFilters({
    date: filter.date,
    startTime: dayjs(filter.startTime).format("HH:mm"),
    endTime: dayjs(filter.endTime).format("HH:mm"),
  });

  const consultationType = filter.consultationType
    ? [filter.consultationType]
    : undefined;

  const bookingConsultationType = filter.bookingConsultationType
    ? [filter.bookingConsultationType]
    : undefined;

  const { clinicalPractitionerIds = [], clientId, resourcePoolId } = filter;

  const clinicalPractitionerId = clinicalPractitionerIds.length
    ? clinicalPractitionerIds.map((id: string) => Number(id))
    : undefined;

  const bookedByClientIds = clientId ? [Number(clientId)] : undefined;

  const status = filter.status ? [filter.status] : defaultStatus;

  const reqFilters: Filter = {
    consultationType,
    bookingConsultationType,
    gender: filter.gender,
    status,
    gMC: filter.gMC,
    iMC: filter.iMC,
    patientName: filter.patientName,
    patientDateOfBirth: filter.patientDateOfBirth,
    resourcePoolId: resourcePoolId ? Number(resourcePoolId) : undefined,
    clinicalPractitionerId,
    bookedByClientIds,
    specialties: filter.specialties,
    start,
    end,
    limit: APT_DISPLAY_LIMIT,
  };

  const validFilter = getValidFilter(reqFilters);

  // To improve performance we are preventing fetching the data if the filters are the same or empty.
  // We have also added debouncing to the publish request to improve performance the and user experience.

  useEffect(() => {
    if (filtersAreEmpty) {
      setData([]);
      return setHasInitialResponse(true);
    }
  }, [filtersAreEmpty]);

  useEffect(() => {
    if (filtersAreEmpty) return;

    const newRequestId = uuid();

    const timer = setTimeout(() => {
      publish("getSchedule", {
        type: "getSchedule",
        payload: [newRequestId, JSON.parse(validFilter)],
      });
      requestId.current = newRequestId;
    }, 500);

    return () => clearTimeout(timer);
  }, [validFilter, filtersAreEmpty, publish]);

  useSubscribe("bookingEvent", response => {
    const { appointments } = response;

    return setData(existingData => {
      return mergeUpdatedAppointments(existingData, appointments);
    });
  });

  useSubscribe("appointmenEvent", response => {
    const { appointments } = response;

    const newAppointments = getNewAptsToDisplay(
      data,
      appointments,
      reqFilters.start
    );

    if (!newAppointments.length) return;

    const newAppointmentIds = newAppointments.map(({ id }) => id);

    return setData(previousData => {
      const filteredPreviousData = previousData.filter(
        el => !newAppointmentIds.includes(el.id)
      );
      return [...filteredPreviousData, ...newAppointments].filter(el =>
        reqFilters.status.includes(el.status)
      );
    });
  });

  useSubscribe("receiveSchedule", (reqId, newData) => {
    if (reqId !== requestId.current) return null;

    setHasInitialResponse(true);
    setError(false);
    return setData(newData);
  });

  return {
    data: formattedData,
    loading: !hasReceivedInitialResponse,
    error: "",
    hasError,
  };
}

export default useListAppointments;
