import { Typography } from "@mui/material";
import dayjs from "dayjs";
import get from "lodash/get";
import { FormDataConsumer } from "ra-core";
import { SelectInput, TextInput } from "ra-ui-materialui";
import React from "react";
import { useFormContext } from "react-hook-form";
import {
  consultationTypeChoices,
  holidayCalendarChoices,
} from "../../../constants/constants";
import { EditTabProps } from "../../../types/utilities";
import TooltipAdornment from "../../booking/TooltipAdornment";
import { FormIteratorAddButton } from "../../form/FormIteratorAddButton";
import { FormIteratorRemoveButton } from "../../form/FormIteratorRemoveButton";
import { SimpleFormIterator } from "../../form/SimpleFormIterator";
import { ArrayInput } from "../../inputs/ArrayInput";
import { DateTimeInput } from "../../inputs/DateTimeInput";
import { TimeInput } from "../../inputs/TimeInput";
import { FormTab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { NumberInput, ReferenceInput, useChoicesContext } from "react-admin";
import {
  APPOINTMENT_HOLD_DURATION_MAXIMUM,
  APPOINTMENT_HOLD_DURATION_MINIMUM,
} from "../../../constants/appointments";
import AutocompleteInput from "../../inputs/AutocompleteInput";
import { nonEmpty } from "../../../validators/nonEmpty";

const headingStyles = {
  display: "flex",
  alignItems: "center",
  width: "100%",
};

const arrayStyles = {
  "& .RaSimpleFormIterator-form > div:first-of-type": {
    mr: 2,
  },
  "& .RaSimpleFormIterator-line": {
    mb: 2,
    maxWidth: "700px",
  },
};

export function ServiceConfigEditTab(props: EditTabProps) {
  const form = useFormContext();

  return (
    <FormTab {...props} label="Service Config">
      <Heading level={2}>Appointment Filters</Heading>
      <Heading level={3} sx={headingStyles}>
        Appointment times
        <TooltipAdornment
          text={
            "Choosing the daily start and end time controls the period for when bookings can be made for this client contract."
          }
        />
      </Heading>
      <TimeInput
        source="appointmentFilters.attributes.parsedStartTime"
        label="Daily start time"
        isRequired
      />
      <TimeInput
        source="appointmentFilters.attributes.parsedEndTime"
        label="Daily end time"
        isRequired
      />
      <Heading level={3} sx={headingStyles}>
        Appointment hold duration
        <TooltipAdornment
          text={
            "Choosing the time duration in minutes overrides the default value of 5 minutes for slots being on hold before the slot is automatically released for other patients."
          }
        />
      </Heading>
      <NumberInput
        source="appointmentFilters.attributes.appointmentHoldDurationMinutes"
        min={APPOINTMENT_HOLD_DURATION_MINIMUM}
        max={APPOINTMENT_HOLD_DURATION_MAXIMUM}
        required
        label="Hold duration in minutes"
      />
      <Heading level={3} sx={headingStyles}>
        Bank Holiday Schedule
        <TooltipAdornment
          text={
            "Selecting a bank holiday schedule means bookings can't be made on these days"
          }
        />
      </Heading>
      <SelectInput
        source="appointmentFilters.clientHolidayCalendars[0].id"
        choices={holidayCalendarChoices}
        label="Holiday Calendar"
      />
      <Heading level={2} sx={headingStyles}>
        Specialty Restrictions
        <TooltipAdornment
          text={
            "Choosing consultation type restrictions based on CP specialty limits a patient's options when creating a booking. Leave empty for no restrictions."
          }
        />
      </Heading>
      <ArrayInput
        source="appointmentFilterCategories"
        label="Categories"
        level={3}
        sx={arrayStyles}
      >
        <SimpleFormIterator
          addButton={<FormIteratorAddButton label="Add appointment filter" />}
        >
          <FormDataConsumer>
            {({ getSource }) => {
              if (!getSource) return null;
              return (
              <>
                <ReferenceInput
                  source={getSource(`id`)}
                  reference="clinicalServicesWithSpecialities"
                  filter={{ deliveryType: "Synchronous" }}
                  required
                >
                  <AutocompleteInput
                    required
                    sx={{ display: "inline-flex" }}
                    label="Category / Clinical Service"
                    optionText="name"
                    filterToQuery={query => ({
                      name: query
                    })}
                    validate={nonEmpty("Select the category or a clinical service.")}
                  />
                </ReferenceInput>
                <SelectInput
                  choices={consultationTypeChoices}
                  source={getSource("consultationType")}
                  label="Consultation Type"
                  required
                  helperText={false}
                />
              </>)

            }}
          </FormDataConsumer>

        </SimpleFormIterator>
      </ArrayInput>
      <Heading level={2} sx={headingStyles}>
        Scheduled Maintenance
        <TooltipAdornment
          text={
            "Adding a scheduled maintenance window means bookings can't be made during that period."
          }
        />
      </Heading>
      <FormDataConsumer>
        {({ formData }) => {
          const contractHasFilters =
            get(formData, "appointmentFilters.attributes.categories") ?? [];

          const hasAptFilter = Boolean(contractHasFilters.length);

          if (!hasAptFilter) {
            return (
              <div>
                <Heading level={3}>
                  No appointment filter exists for this contract
                </Heading>
                <Typography variant="body1">
                  You can create an appointment filter from within the Service
                  Config tab
                </Typography>
              </div>
            );
          }

          return (
            <ArrayInput
              label="Upcoming maintenance windows"
              source="maintenanceWindows"
              level={3}
            >
              <SimpleFormIterator
                addButton={
                  <FormIteratorAddButton label="Add maintenance window" />
                }
                disableRemove={record => {
                  const startTime = get(record, "attributes.start");
                  const isStartTimeInPast = dayjs().isAfter(startTime);

                  return isStartTimeInPast;
                }}
                removeButton={
                  <FormIteratorRemoveButton label="Remove maintenance window" />
                }
              >
                <FormDataConsumer>
                  {({ scopedFormData, getSource }) => {
                    if (!getSource) return;

                    const startTime = get(scopedFormData, "attributes.start");
                    const isStartTimeInPast = dayjs().isAfter(startTime);
                    const isUpdateDisabled =
                      isStartTimeInPast && Boolean(scopedFormData.id);

                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "300px",
                          paddingTop: 8,
                        }}
                      >
                        {isUpdateDisabled && (
                          <Typography variant="body1">
                            Note: You cannot change a past maintenance window
                          </Typography>
                        )}
                        <TextInput
                          required
                          disabled={isUpdateDisabled}
                          source={getSource("attributes.description")}
                          label="Description"
                        />
                        <DateTimeInput
                          disabled={isUpdateDisabled}
                          label="Start"
                          source={getSource("attributes.start")}
                          hasHelperText
                        />
                        <DateTimeInput
                          disabled={isUpdateDisabled}
                          label="End"
                          source={getSource("attributes.end")}
                          hasHelperText
                          onBlur={() => {
                            form.trigger();
                          }}
                        />
                      </div>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          );
        }}
      </FormDataConsumer>
    </FormTab>
  );
}
