import { UpdateParams } from "ra-core";
import { update } from "..";

interface Body {
  id: string;
  attributes: {
    name: string;
    code: string;
  };
}

async function updateEmployer({ data }: UpdateParams<Body>) {
  const { id, attributes } = data as Body;
  const updateEmployersEndpoint = `ms/employers/${id}`;
  const { name, code } = attributes;

  const updateEmployerBody = {
    data: {
      type: "employers",
      id,
      attributes: {
        name,
        code,
      },
    },
  };

  const employerRes = await update(updateEmployersEndpoint, updateEmployerBody);

  return employerRes;
}

export default updateEmployer;
