import { GetListParams } from "react-admin";
import { list } from "..";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";
import { BOOKING_EMPLOYER_OTHER_IDENTIFIER } from "../../constants/employer";

async function listEmployers(params: GetListParams) {
  const employersEndpoint = "ms/employers";

  const { pagination, filter, sort } = params;
  const { page, perPage } = pagination;

  const { name, clientId, includeOther } = filter;

  const filterParams = {
    "filter[name]": name ? `like:${name}` : "",
    "filter[clientId]": clientId ? clientId : "",
  };

  const pageParams = {
    "page[number]": page,
    "page[size]": perPage,
  };

  const sortQuery = `${sort.order === "DESC" ? "-" : ""}${sort.field}`;

  const query = {
    sort: sortQuery,
    ...pageParams,
    ...filterParams,
  };

  const queryParams = shallowlyRemoveEmptyValues(query);

  const { data, total } = await list(employersEndpoint, {
    params: queryParams,
  });

  if (includeOther) {
    data.push({
      type: "employers",
      id: BOOKING_EMPLOYER_OTHER_IDENTIFIER,
      attributes: {
        clientId,
        code: "",
        name: "Other",
      },
    })
  }

  return { data, total, pageInfo: { hasNextPage: perPage * page < total, hasPreviousPage: page !== 1 } };
}

export default listEmployers;
