export const isExclusiveMessage =
  "If the resource pool is marked exclusive the client can only book appointments from these CPs.";

export const ignoreClientContractRequirementsMessage =
  "If enabled, shows all available clinical practitioners regardless of registration/qualification";

export const ignoreOpeningTimesMessage =
  "If enabled, ignores the client's operational hours and shows all available appointments in a 24 hour period";

export const isCpEnabledMessage =
  "A CP can only be assigned appointments if they are enabled.";

export const isCpExternalMessage =
  "An external CP is a user who is not directly employed by the business";

export const dependantMessage =
  "A user who is either under the age of majority or who requires guardianship/care by another designated adult";

export const clientIdentifierMessage =
  "This can be a code or a membership number, and is used to validate client eligibility";

export const isDefaultMessage =
  "The default contract will take precedence over any active contracts";

export const backToBackAppointmentsMessage =
  "All appointments are created back-to-back with no breaks, unless breaks have been explicitly added";

export const patientNotVerifiedBookingDisabledMessage =
  "This patient is required to verify their identity before being able to book an appointment.";

export const clientDiscardedBookingDisabledMessage =
  "This patient cannot have new bookings created as they belong to a disabled client.";
