import React from "react";
import { SelectInput, useGetOne } from "react-admin";
import { splitByCapitalLetters } from "../../utilities/splitByCapitalLetters";
import { Typography } from "@mui/material";
import { useWatch } from "react-hook-form";

type Choice = { name: string; id: string };
interface AppointmentFilterInputsProps {
  clientId: string;
}
export const getConsultationTypeChoices = (
  serviceId: string | number | undefined,
  email: string | undefined,
  data: Record<string, any>
): Choice[] => {
  if (!data) return [];

  // All consultation types available
  if (!data?.appointmentFiltersEnabled)
    return data.consultationTypes;

  // Service isn't selected yet - return all available types
  if (!serviceId)
    return data?.availableConsultationTypes;

  // Service is selected - return all available types for the service
  const service = data?.availableServices?.find((s: any) => s.id == serviceId);
  return service?.consultationTypes?.filter((ct: string) => email || ct === "Phone")
    .map((ct: string) => {
      return {
        id: ct,
        name: splitByCapitalLetters(ct),
      };
    });
};

export const ConsultationTypeInput = ({
  clientId: id,
}: AppointmentFilterInputsProps) => {
  const { data, isLoading } = useGetOne("availableServices", { id });
  const service = useWatch({name: "service"});
  const email = useWatch({name: "email"});
  const choices = getConsultationTypeChoices(service, email, data) ?? [];

  if (isLoading) return null;

  return (
    <>
      {!email ? (
        <Typography>
          This patient doesn't have an email address, and will not be able
          to make Video, MAD or Onsite bookings
        </Typography>
      ) : null}
      <SelectInput
        isLoading={isLoading}
        disabled={!choices?.length}
        source="consultationType"
        label="Consultation Type"
        required
        choices={choices}
      />
    </>
  );
};
