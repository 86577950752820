import React, { useState } from "react";
import { DateField } from "../../../fields/DateField";
import {
  EditBase, FunctionField,
  RecordContextProvider,
  SimpleList,
  useEditContext,
  useRecordContext
} from "react-admin";
import { useCreate, useGetOne, useNotify } from "ra-core";
import {  Typography } from "@mui/material";
import { ArrayField, Button, Labeled, TextField } from "ra-ui-materialui";
import { Box } from "@mui/system";
import { FormTabWithPermission } from "../../../form/CustomTabForm/FormTabsWithPermission";
import { FileInput } from "../../../inputs/FileInput";
import { Group } from "../../../layout/Group";
import { Stack } from "../../../layout/Stack";
import { Heading } from "../../../ui/Heading";
import { FileImageField } from "../../../fields/FileImageField";
import { trackEvent } from "../../../../utilities/trackEvent";
import { EditTabProps } from "../../../../types/utilities";
import { PharmacyDetailsInputs } from "../../../healthmail/PharmacyDetailsInputs";
import { ReprocessPrescriptionModal } from "../../../healthmail/ReprocessPrescriptionModal";
import validateHealthMailSend from "../../../healthmail/validateHealthMailSend";
import { LinkDataIssueMessage } from "./LinkDataIssueMessage";
import ConsultationOutputDownloadButton from "../../../consultationOutputs/ConsultationOutputDownloadButton";
import get from "lodash/get";
import { HealthmailRaRecord } from "../../../../types/healthmail";
import {
  TOGGLE_ENABLE_HEALTHMAIL_FULFILLMENT,
  TOGGLE_USE_EXISTING_HEALTHMAIL_DOCUMENT
} from "../../../../featureFlags/flags";
import { useFlag } from "../../../../featureFlags";
import { ADASTRA_CASE_NUMBER_SYSTEM_IDENTIFIER } from "../../../../constants/gaia";
import { Section } from "../DocumentsTab/AttachmentsSection";

interface HealthMailSendProps extends EditTabProps {
  caseRef: string;
  consultationId: string
}

export function HealthMailSendTab(props: HealthMailSendProps) {
  const isHealthmailFulfillmentEnabled = useFlag(TOGGLE_ENABLE_HEALTHMAIL_FULFILLMENT);
  const notify = useNotify();
  const [sendMail, { isLoading: isSubmitting }] = useCreate();
  const [submissionErrorCode, setErrorCode] = useState<null | number>(null);

  async function handleSubmit(formData: any) {
    sendMail(
      "gaia:sendHealthmail",
      {
        data: {
          ...formData,
          consultationId: props.consultationId
        }
      },
      {
        onSuccess: () => {
          notify("The mail has been sent successfully");
          trackEvent("Healthmail", "Send Prescription");
        },
        onError: err => {
          setErrorCode((err as any).status);
          notify((err as any).message, { type: "error" });
        },
      }
    );
  }

  return (
    <EditBase 
      resource="healthmail"
      id={props.caseRef}
      consultationId={props.consultationId}
      queryOptions={{ meta: { defaultOnError: true } }}
    >
      <FormTabWithPermission
        {...props}
        label="Healthmail"
        editable="hidden"
        options={{
          resource: "healthmail",
          permission: "enabled",
        }}
        save={handleSubmit}
        validate={validateHealthMailSend}
      >
      {isHealthmailFulfillmentEnabled ?
        <TabContentsWithFulfillment
          {...props}
          submissionErrorCode={submissionErrorCode}
          isSubmitting={isSubmitting}
        /> :
        <TabContents
          {...props}
          submissionErrorCode={submissionErrorCode}
          isSubmitting={isSubmitting}
        />}
      </FormTabWithPermission>
    </EditBase>
  );
}

function TabContentsWithFulfillment(props: any) {
  const { isLoading } = useEditContext();
  const record = useRecordContext();
  const hasFoundHealthmailData = Boolean(record?.encounter);

  const encounterId = record?.encounter?.id.split(":").pop();

  const {
    data: fulfillmentData,
    isLoading: isFulfillmentLoading
  } = useGetOne("gaia:fulfillmentDetails", { id: encounterId });

  const isSentToPharmacy =
    !isFulfillmentLoading &&
    fulfillmentData.fulfillments?.length > 0 &&
    Boolean(fulfillmentData.fulfillments[0].pharmacy);


  return (
    <>
      {!isLoading && !hasFoundHealthmailData && <LinkDataIssueMessage />}
      {isSentToPharmacy && <FulfillmentDetails record={fulfillmentData} /> }

      {!isLoading && !isFulfillmentLoading && hasFoundHealthmailData && !isSentToPharmacy &&
        <Stack sx={{ px: 2, py: 1 }}>
          <Group heading={<Heading level={2}>Pharmacy Details</Heading>}>
            <PharmacyDetailsForm isLoading={props.isSubmitting} submissionErrorCode={props.submissionErrorCode} />
          </Group>
        </Stack>
      }
    </>
  );
}

function TabContents(props: any) {
  const { isLoading } = useEditContext();
  const record = useRecordContext();
  const hasFoundHealthmailData = Boolean(record?.encounter);

  return (
    <>
      {!isLoading && !hasFoundHealthmailData && <LinkDataIssueMessage />}
      {!isLoading && hasFoundHealthmailData &&
        <Stack sx={{ px: 2, py: 1 }}>
          <Group heading={<Heading level={2}>Pharmacy Details</Heading>}>
            <PharmacyDetailsForm isLoading={props.isSubmitting} submissionErrorCode={props.submissionErrorCode} />
          </Group>
        </Stack>
      }
    </>
  );
}



function FulfillmentDetails(props: any) {
  const record = useRecordContext<HealthmailRaRecord>();
  const prescription = record.documents?.find(d => d.type == 'medication-prescription');
  const caseNo = record.encounter.identifiers.find(i => i.system == ADASTRA_CASE_NUMBER_SYSTEM_IDENTIFIER)!.value;

  return (
    <RecordContextProvider value={props.record}>
      <Group>
        <Stack>
          <Section>
            <Labeled label={"Pharmacy name"}>
              <TextField source={"fulfillments[0].pharmacy.name"} />
            </Labeled>
            <Labeled label={"Pharmacy email"}>
              <TextField source={"fulfillments[0].pharmacy.email"} />
            </Labeled>
            <Labeled label={"Pharmacy address"}>
              <>
                <FunctionField render={(rec: any ) => {
                  const address = rec.fulfillments[0].pharmacy.address;
                  const addressStrings = [...address.lines, address.city, address.state, address.postCode];
                  return addressStrings.filter((x:any) => x).map((line:string, index: number) => (<Typography key={index}>{line}</Typography>));
                }} />
              </>
            </Labeled>
            <Labeled label={"Fulfillment Status"}>
              <TextField label={"Fulfillment Status"} source={"fulfillments[0].state"} />
            </Labeled>
            <Labeled label={"Prescription sent at"}>
              <DateField
                label={"Prescription sent at"}
                source={"fulfillments[0].sentToProviderOn"}
                showTime
                hideLabel
                emptyText={"unknown"}
                format={"DD/MM/YY HH:mm"}
              />
            </Labeled>
          </Section>
          <Labeled label={"Medications"}>
            <ArrayField source={"fulfillments"}>
              <SimpleList
                linkType={false}
                primaryText={(rec) =>
                  (<>
                    <Typography>
                      {rec.displayText}
                    </Typography>
                    <Typography>
                      {rec.dosage}
                    </Typography>
                    <Typography>
                      {`Quantity: ${rec.quantity.value} ${rec.quantity.units}`}
                    </Typography>
                  </>)}
              >
              </SimpleList>
            </ArrayField>
          </Labeled>
        </Stack>
        <DownloadPrescription
          prescription={prescription}
          caseNumber={caseNo}
          patient={record.patient}
          encounter={record.encounter}
        />
      </Group>
    </RecordContextProvider>
  );
}

type PharmacyDetailsFormProps = {
  isLoading: boolean;
  submissionErrorCode: null | number;
};

function PharmacyDetailsForm(props: PharmacyDetailsFormProps) {
  const { isLoading, submissionErrorCode } = props;
  const record = useRecordContext<HealthmailRaRecord>();
  const documents = get(record, 'documents');
  const prescription = documents?.find(d => d.type == 'medication-prescription');

  const useExistingHealthmailDocument = useFlag(TOGGLE_USE_EXISTING_HEALTHMAIL_DOCUMENT);
  if (useExistingHealthmailDocument) {
    record.documentId = prescription?.id
  }

  const caseNo = record.encounter.identifiers.find(i => i.system == ADASTRA_CASE_NUMBER_SYSTEM_IDENTIFIER)!.value;

  return (
    <Stack>
      <PharmacyDetailsInputs />

      {useExistingHealthmailDocument && prescription != null ? (
        <DownloadPrescription
          prescription={prescription}
          caseNumber={caseNo}
          patient={record.patient}
          encounter={record.encounter}
        />
      ) : (
      <FileInput
        source="file"
        label="Patient's Prescription"
        accept="application/pdf"
      >
        <FileImageField />
      </FileInput>
      )}

      <Typography>
        Note: A standard template will be sent with the email
      </Typography>
      <Box>
        <SendButton hasError={submissionErrorCode === 409} isLoading={isLoading} />
      </Box>
      {useExistingHealthmailDocument ? null : (<Box>
        <ReprocessButton disabled={submissionErrorCode !== 409} />
      </Box>)}
    </Stack>
  );
}


interface DownloadPrescriptionProps {
  caseNumber: string;
  prescription: any;
  encounter: any;
  patient: any;
}

function DownloadPrescription(props: DownloadPrescriptionProps) {
  return (<RecordContextProvider value={props.prescription}>
      <Box sx={{ display: "flex", gap: "8px" }}>
        <ConsultationOutputDownloadButton
          label="Download with password"
          applyPassword
          caseNo={props.caseNumber}
          encounterStartTime={props.encounter.startTime}
          patientDob={props.patient.birthDate}
          variant="contained"
        />
        <ConsultationOutputDownloadButton
          label="Download without password"
          applyPassword={false}
          caseNo={props.caseNumber}
          encounterStartTime={props.encounter.startTime}
          patientDob={props.patient.birthDate}
        />
      </Box>
    </RecordContextProvider>
    )
}

interface SendButtonProps {
  isLoading: boolean;
  hasError: boolean;
}

function SendButton(props: SendButtonProps) {
  const { isLoading, hasError } = props;

  return (
    <Button
      disabled={isLoading || hasError}
      variant="contained"
      color="primary"
      size="medium"
      label="Send Prescription"
      type="submit"
    />
  );
}

function ReprocessButton(props: { disabled?: boolean }) {
  const { disabled = false } = props;
  const [isModalOpen, setModalOpenState] = useState(false);

  const handleClose = () => setModalOpenState(false);

  return (
    <>
      <Button
        disabled={disabled}
        variant="outlined"
        onClick={() => {
          setModalOpenState(true);
        }}
        color="primary"
        size="medium"
        label="Reprocess Prescription"
        type="button"
      />
      <ReprocessPrescriptionModal
        open={isModalOpen}
        handleClose={handleClose}
      />
    </>
  );
}
