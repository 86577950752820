import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { version } from "./version";
import isEmpty from "lodash/isEmpty";

export function initMonitoring() {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
    site: "datadoghq.eu",
    service: "ed3",
    env: process.env.REACT_APP_ENV ?? "development",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    version:
      process.env.REACT_APP_ENV !== "development" ? version : "local_dev",
  });

  initRumSession();
}

function initRumSession() {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID ?? "",
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
    site: "datadoghq.eu",
    service: "ed3",
    env: process.env.REACT_APP_ENV ?? "development",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    traceSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask",
    allowedTracingUrls: [/https:\/\/.*\.healthhero\.com/],
    version:
      process.env.REACT_APP_ENV !== "development" ? version : "local_dev",
  });
}

export function setSessionUser(userId: string, email: string, role: string) {
  datadogRum.setUser({
    id: userId,
    email,
    role
  })
}

export const restartRumSession = () => {
  const user = datadogRum.getUser();
  if (isEmpty(user)) return;

  datadogRum.stopSession();
  initRumSession();
};


