import { GetManyReferenceParams } from "ra-core";
import { list } from "..";



async function getManyClientEmployers(params: GetManyReferenceParams) {
  const { id: clientId, pagination, sort } = params;

  const { page, perPage } = pagination;
  const { field, order } = sort;
  const sortQuery = `${order === "DESC" ? "-" : ""}${field}`;


  const endpoint = "/ms/employers";

  const query = {
    sort: sortQuery,
    "page[size]": perPage,
    "page[number]": page,
    "filter[clientId]": clientId,
  };

  const { data, total } = await list(endpoint, { params: query });

  return {data, total};

}

export default getManyClientEmployers;
