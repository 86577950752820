import { Permissions } from "./types/permissions";

export const permissions: Permissions = {
  Administrator: {
    adminAppointments: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    schedule: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    clients: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    clinicalPractitioners: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    patientRecords: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    resourceManagement: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    workQueues: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    bookings: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    recordings: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    importShiftsProcessed: {
      create: true
    },
    shifts: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    employers: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    documents: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    healthmail: {
      enabled: true,
      list: true,
      show: true,
    },
    resourcePools: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    clinicalServices: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    gpSurgeries: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    pharmacies: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    } 
  },
  ClientAdmin: {
    adminAppointments: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    schedule: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    clients: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    resourcePools: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    clinicalServices: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    clinicalPractitioners: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    patientRecords: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    resourceManagement: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    workQueues: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    bookings: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    recordings: {
      show: false,
    },
    importShiftsProcessed: {
      create: true
    },
    shifts: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    employers: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    documents: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    healthmail: {
      enabled: true,
      list: true,
      show: true,
    },
    gpSurgeries: {
      enabled: true,
      list: true,
      show: true,
    },
    pharmacies: {
      enabled: true,
      list: true,
      show: true,
    } 
  },
  ClinicalPractitioner: {
    adminAppointments: {
      enabled: false,
      create: false,
      edit: false,
      list: false,
      show: false,
    },
    schedule: {
      enabled: false,
      create: false,
      edit: false,
      list: false,
      show: false,
    },
    clients: {
      enabled: true,
      create: false,
      edit: false,
      list: true,
      show: false,
    },
    resourcePools: {
      enabled: true,
      create: false,
      edit: false,
      list: true,
      show: false,
    },
    clinicalServices: {
      enabled: true,
      create: false,
      edit: false,
      list: true,
      show: false,
    },
    clinicalPractitioners: {
      enabled: false,
      create: false,
      edit: false,
      list: false,
      show: false,
    },
    patientRecords: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    resourceManagement: {
      enabled: false,
      create: false,
      edit: false,
      list: false,
      show: false,
    },
    workQueues: {
      enabled: false,
      create: false,
      edit: false,
      list: false,
      show: false,
    },
    bookings: {
      enabled: false,
      create: false,
      edit: false,
      list: false,
      show: false,
    },
    recordings: {
      show: false,
    },
    shifts: {},
    importShiftsProcessed: {},
    employers: {},
    documents: {},
    healthmail: {},
    gpSurgeries: {},
    pharmacies: {},
  },
  CSO: {
    adminAppointments: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    schedule: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    clients: {
      enabled: true,
      create: false,
      edit: false,
      list: true,
      show: true,
    },
    resourcePools: {
      enabled: true,
      create: false,
      edit: false,
      list: true,
      show: true,
    },
    clinicalServices: {
      enabled: true,
      create: false,
      edit: false,
      list: true,
      show: true,
    },
    clinicalPractitioners: {
      enabled: true,
      create: false,
      edit: false,
      list: true,
      show: true,
    },
    patientRecords: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    resourceManagement: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    workQueues: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    bookings: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    recordings: {
      show: false,
    },
    importShiftsProcessed: {
      create: true
    },
    shifts: {
      enabled: true,
      list: true,
      show: true,
      create: true,
    },
    employers: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    documents: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    healthmail: {
      enabled: true,
      list: true,
      show: true,
    },
    gpSurgeries: {
      enabled: true,
      list: true,
      show: true,
    },
    pharmacies: {
      enabled: true,
      list: true,
      show: true,
    }
  },
  Patient: {
    adminAppointments: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    schedule: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    clients: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    resourcePools: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    clinicalServices: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    clinicalPractitioners: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    patientRecords: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    resourceManagement: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    workQueues: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    bookings: {
      enabled: true,
      create: true,
      edit: true,
      list: true,
      show: true,
    },
    recordings: {
      show: false,
    },
    shifts: {},
    importShiftsProcessed: {},
    employers: {},
    documents: {},
    healthmail: {},
    gpSurgeries: {},
    pharmacies: {}
  },
};
