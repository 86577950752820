import { create, getAll, update } from "..";
import { ClientIdentifierDTO } from "../../types/validation";

export type ClientIdentifier = {
  id: string;
  clientIdentifier: string;
  name?: string;
  isEnabled: boolean;
  clientId: string;
}

type Args = {
  clientId: string;
  identifiers: ClientIdentifier[];
  newIdentifiers: ClientIdentifier[];
  isEnabled: boolean;
};

async function updateIdentifiers(args: Args) {
  const { clientId, newIdentifiers: newIds, isEnabled } = args;

  if (!isEnabled) return;

  if (!newIds.length) return;

  const clientIdentifiersEndpoint = `/ms/clients/${clientId}/identifiers?page[size]=100`;
  const { data: clientIdentifiersData } = await getAll(
    clientIdentifiersEndpoint
  );

  const currentIdentifiers: ClientIdentifier[] = clientIdentifiersData.map(
    (identifier: ClientIdentifierDTO["data"]) => ({
        id: identifier.id,
        ...identifier.attributes,
    })
  );

  const batchIdentifiersEndpoint = `/ms/clients/${clientId}/batchIdentifiers`;

  currentIdentifiers.forEach(i => i.name = newIds.find(ci => ci.clientIdentifier.toLowerCase() === i.clientIdentifier.toLowerCase())?.name)

  const newIdsLowerCase = newIds.map(id => id.clientIdentifier.toLowerCase());

  const toEnable = currentIdentifiers.filter(i => newIdsLowerCase.includes(i.clientIdentifier.toLowerCase()));
  toEnable.forEach(x => x.isEnabled = true);

  const toDisable = currentIdentifiers.filter(i => !newIdsLowerCase.includes(i.clientIdentifier.toLowerCase()))
  toDisable.forEach(x => x.isEnabled = false);


  const updateIdentifiersBody = {
    data: {
      type: "batchClientIdentifiers",
      id: clientId,
      attributes: {
        clientIdentifiers: [
          ...toEnable,
          ...toDisable,
        ]
      },
    },
  };

  try {
    if (updateIdentifiersBody.data.attributes.clientIdentifiers.length) {
      await update(batchIdentifiersEndpoint, updateIdentifiersBody);
    }
  } catch (err) {
    console.error("error updating identifiers", err);
  }

  const currentIdsLowerCase = currentIdentifiers.map(i => i.clientIdentifier.toLowerCase());
  const toCreate = newIds.filter(id => !currentIdsLowerCase.includes(id.clientIdentifier.toLowerCase()));
  toCreate.forEach(i => i.id = '0'); // Hack to trick JSON.API validation


  const createIdentifiersBody = {
    data: {
      type: "batchClientIdentifiers",
      attributes: {
        clientIdentifiers: toCreate,
      },
    },
  };

  try {
    if (toCreate.length) {
      await create(batchIdentifiersEndpoint, createIdentifiersBody);
    }
  } catch (err) {
    console.error("error creating identifiers", err);
  }
}

export default updateIdentifiers;
