import { ChangeAction, ClientEmployerChange } from "../../types/clientEmployer";
import createEmployer from "./createEmployer";
import deleteManyEmployers from "./deleteManyEmployers";
import updateEmployer from "./updateEmployer";


type Args = {
  clientEmployersChanges: ClientEmployerChange[];
};

async function updateManyEmployers(args: Args) {
  const { clientEmployersChanges } = args;

  const employersToAdd =
    clientEmployersChanges
    .filter(r => r.action === ChangeAction.Added)
    .map(x => x.payload);


  const employersToUpdate =
    clientEmployersChanges
    .filter(r => r.action === ChangeAction.Edited)
    .map(x => x.payload);


  const idsToDelete = clientEmployersChanges
    .filter(r => r.action === ChangeAction.Deleted)
    .map(x => x.id);

  const createManyPromises = employersToAdd.map(createEmployer);
  const updateManyPromises = employersToUpdate
    .map(employer => {
        // @ts-ignore
        return updateEmployer({
          id: employer.id,
          data: {
            id: employer.id,
            attributes: {
              name: employer.attributes.name,
              code: employer.attributes.code
            }
          }
        });
      }
    );

  const deleteManyPromises = deleteManyEmployers({ ids: idsToDelete });

  try {
    await Promise.all([
      ...createManyPromises,
      ...updateManyPromises,
      deleteManyPromises,
    ]);
  } catch (err) {
    console.error(err);
  }
}

export default updateManyEmployers;
