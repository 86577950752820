import React from "react";
import { Card, Typography } from "@mui/material";

export const NoEmployersMessage = (props: { isEditPage: boolean }) => (
  <Card style={{ padding: 16 }}>
    <Typography variant="body1">
      No employers are associated with this client.{" "}
      {props.isEditPage ? "Click 'Add' to create one." : ""}
    </Typography>
  </Card>
);
