export interface ClientEmployerDTO {
  data: {
    id: string;
    type?: "employers";
    attributes: {
      clientId: string;
      code: string;
      name: string;
    };
  };
}

// eslint-disable-next-line no-shadow
export enum ChangeAction {"Added" = 0, "Edited" = 1, "Deleted" = 2}

export interface ClientEmployerChange {
  id: string,
  payload: ClientEmployerDTO["data"],
  oldName?: string,
  action: ChangeAction
}

