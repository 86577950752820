export const TOGGLE_SCHEDULER_APPOINTMENT_HOLDS =
  "ed-3-scheduler-appointment-holds";
export const TOGGLE_DISPLAY_BOOKING_PAYMENT_ACTIONS =
  "ed-3-display-booking-payment-actions";
export const TOGGLE_USE_EXISTING_HEALTHMAIL_DOCUMENT =
  "ed-3-use-existing-healthmail-document";
export const TOGGLE_NEW_BRANDING =
  "ed-3-use-new-branding";
export const TOGGLE_USE_HEALTHMAIL_PHARMACY_LOOKUP =
  "ed-3-healthmail-pharmacy-search";
export const TOGGLE_KYC = "ed-3-enable-kyc";
export const TOGGLE_OTP = "ed-3-enable-otp";
export const TOGGLE_USE_PHARMACY_MANAGEMENT = 
  "ed-3-enable-pharmacy-management";
export const TOGGLE_ENABLE_CLINICAL_SERVICES =
  "ed-3-enable-clinical-services";
export const TOGGLE_ENABLE_GP_SURGERIES =
  "ed-3-enable-gp-surgery-management";

export const TOGGLE_ENABLE_HEALTHMAIL_FULFILLMENT =
  "ed-3-enable-healthmail-fulfillment"

export const TOGGLE_APPOINTMENTS_PAGE_ENBALED =
  "ed-3-appointments-page-enabled";

export const TOGGLE_ENABLE_NEW_SHIFT_IMPORT =
  "ed-3-enable-new-shift-import";
