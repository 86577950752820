import React from "react";
import { Filter, ReferenceInput, SelectArrayInput, SelectInput, TextInput } from "react-admin";
import { WorkQueueItemType, WorkQueueStatus } from "../../types/workQueues";
import AutocompleteInput from "../inputs/AutocompleteInput";

type Choice<T extends string> = {
  name: string;
  id: T;
}[];

const statusChoices: Choice<WorkQueueStatus> = [
  {
    name: "Assigned",
    id: "Assigned",
  },
  {
    name: "Completed",
    id: "Completed",
  },
  {
    name: "Unassigned",
    id: "Unassigned",
  },
];

const actionChoices: Choice<WorkQueueItemType> = [
  {
    name: "Allocation Required",
    id: "AllocationRequired",
  },
  {
    name: "Cancel Booking",
    id: "CancelBooking",
  },
  {
    name: "Follow Up Action",
    id: "FollowUpAction",
  },
  {
    name: "Manual Intervention Required",
    id: "ManualInterventionRequired",
  },
  {
    name: "Message Doctor Allocation Required",
    id: "MessageDoctorAllocationRequired",
  },
  {
    name: "Notification Failed",
    id: "NotificationFailed",
  },
  {
    name: "Send Prescription",
    id: "SendPrescription",
  },
  {
    name: "Capture Payment",
    id: "CapturePayment",
  },
  {
    name: "Send Consultation Notes To GP",
    id: "SendConsultationNotesToGP"
  },
  {
    name: "Add Patient GP Surgery",
    id: "AddPatientSurgery"
  },
  {
    name: "Form Fill Consultation Action Required",
    id: "FormFillConsultationActionRequired"
  },
  {
    name: "Send Form Fill Documents To GP",
    id: "SendFormFillDocumentsToGp"
  },
  {
    name: "ADHD Booking Too Far",
    id: "AdhdBookingTooFar"
  }
];

export function WorkQueueFilter(props: any) {
  return (
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        source="assignedToUserId"
        reference="resourceManagement"
      >
        <AutocompleteInput
          label="Assignee"
          placeholder="Search by CSO email..."
          source="id"
          optionText="attributes.email"
          helperText={false}
        />
      </ReferenceInput>
      <SelectInput
        source="status"
        label="Status"
        choices={statusChoices}
        alwaysOn
      />
      <SelectArrayInput
        source="itemType"
        label="Type"
        choices={actionChoices}
        alwaysOn
      />
      <TextInput alwaysOn label="Booking ID" source="bookingId" />
      <TextInput alwaysOn label="Thread ID" source="threadId" />
      <TextInput alwaysOn label="PMS Case No" source="caseNumber" />
    </Filter>
  );
}
