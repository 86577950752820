import React from "react";
import { Labeled, useRecordContext } from "react-admin";
import { LinkButtonBase } from "../buttons/LinkButtonBase";
import { Typography } from "@mui/material";
import get from "lodash/get";

export function HealthmailLink() {
  const record = useRecordContext();

  const booking = get(record, "bookings[0]");
  const adastraCaseRef = get(
    booking,
    "patientManagementSystemCases[0].attributes.caseNumber"
  );

  const clientId = get(record, "clients[0].id");
  const patientRecordId = get(record, "patients[0].id");
  const bookingId = get(booking, "id");

  const to = {
    pathname: `/patientRecords/${patientRecordId}/clients/${clientId}/bookings/${bookingId}/healthmail`
  };

  if (!adastraCaseRef) {
    return (
      <Labeled label="Link to prescription" data-testid="no-healthmail-link">
        <Typography variant="body1">No Associated Case</Typography>
      </Labeled>
    );
  }

  return (
    <Labeled label="Link to prescription">
      <LinkButtonBase to={to} text="View Document" />
    </Labeled>
  );
}
