import { UpdateParams } from "react-admin";
import updateNotificationSettings from "./updateNotificationSettings";
import updateAppointmentFilters from "./updateAppointmentFilters";
import updateClientAttributes from "./updateClientAttributes";
import updateContracts from "./updateContracts";
import updateIdentifiers, { ClientIdentifier } from "./updateIdentifiers";
import updatePayments from "./updatePayments";
import updateSendGridIds from "./updateSendGridIds";
import updateNotificationChannelSettings from "./updateNotificationChannelSettings";
import updateNotificationTypeSettings from "./updateNotificationTypeSettings";
import updatePatientIdentifiers from "./updatePatientIdentifiers";
import updateValidationRules from "./updateValidationRules";
import updateClientTerms from "./updateClientTerms";
import updateFollowUpActions from "./updateFollowUpActions";
import updateExternalValidation from "./updateExternalValidation";
import updateInstructions from "./updateInstructions";
import updateAssignedPMS from "./updateAssignedPMS";
import updateMaintenanceWindows from "./updateMaintenanceWindows";
import {
  ClientFunctionValidationDTO,
  PatientValidationDTO,
  ValidationRules,
} from "../../types/validation";
import {
  AdminClientDTO,
  ClientBookingChannelsDTO,
  ClientContractDTO,
  ClientFollowUpActions,
  ClientInstructionsDTO,
  MaintenanceWindowsDTO,
  MasterClientGroupDTO,
  TermsDTO,
} from "../../types/clients";
import {
  BatchSendGridTemplateDTO,
  ChannelSettingsDTO,
  ChannelTypeDTO,
  NotificationChannel,
  NotificationSettingsDTO,
  NotificationType,
} from "../../types/notifications";
import { PaymentSettingsDTO } from "../../types/payments";
import { AppointmentFilterCategory, AppointmentFiltersDTO } from "../../types/appointments";
import { ClientPatientManagementSystemMappingDTO } from "../../types/patientManagementSystems";
import updateMasterClient from "./updateMasterClient";
import { EmployerSettingsDTO } from "../../types/employerSettings";
import updateEmployerSettings from "./updateEmployerSettings";
import { ClientEmployerChange, ClientEmployerDTO } from "../../types/clientEmployer";
import updateManyEmployers from "../employers/updateManyEmployers";
import updateClientBookingChannels from "./updateClientBookingChannels";
import updateClientClinicalPools from "./updateClientClinicalPools";
import { ClientClinicianPoolsDTO } from "../../types/resourcePools";

interface Body {
  id: string;
  notify: (message: string) => void;
  attributes: AdminClientDTO["data"]["attributes"];
  existingAttributes: AdminClientDTO["data"]["attributes"];
  contract: ClientContractDTO["data"];
  existingContract: ClientContractDTO["data"];
  notificationSettings: NotificationSettingsDTO["data"];
  existingNotificationSettings: NotificationSettingsDTO["data"];
  existingNotificationChannelSettings: ChannelSettingsDTO["data"][];
  notificationChannelSettings: NotificationChannel[];
  notificationTypeSettings: NotificationType[];
  existingNotificationTypeSettings: ChannelTypeDTO["data"][];
  templateIds: BatchSendGridTemplateDTO["data"];
  existingTemplateIds: BatchSendGridTemplateDTO["data"];
  oldTerms: TermsDTO["data"];
  terms: TermsDTO["data"];
  oldValidationRules: ValidationRules;
  validationRules: ValidationRules;
  newIdentifiers?: ClientIdentifier[];
  identifiers?: ClientIdentifier[];
  clientFunctionValidation: ClientFunctionValidationDTO["data"];
  patientIdentifiers: PatientValidationDTO["data"];
  newPatientIdentifiers: PatientValidationDTO["data"];
  payments: PaymentSettingsDTO["data"];
  oldPayments: PaymentSettingsDTO["data"];
  clientClinicianPools: ClientClinicianPoolsDTO["data"][];
  oldClientClinicianPools: ClientClinicianPoolsDTO["data"][];
  appointmentFilters: AppointmentFiltersDTO["data"];
  oldAppointmentFilters: AppointmentFiltersDTO["data"];
  oldFollowUpActions: ClientFollowUpActions["data"];
  followUpActions: ClientFollowUpActions["data"];
  oldInstructions: ClientInstructionsDTO["data"];
  instructions: ClientInstructionsDTO["data"];
  oldAssignedPMS: ClientPatientManagementSystemMappingDTO[];
  assignedPMS: ClientPatientManagementSystemMappingDTO[];
  oldMaintenanceWindows: MaintenanceWindowsDTO["data"];
  maintenanceWindows: MaintenanceWindowsDTO["data"];
  masterClientGroup: MasterClientGroupDTO["data"];
  oldMasterClientGroup: MasterClientGroupDTO["data"];
  employerSettings: EmployerSettingsDTO["data"];
  oldEmployerSettings: EmployerSettingsDTO["data"];
  clientEmployers: ClientEmployerDTO["data"][];
  clientBookingChannels: ClientBookingChannelsDTO["data"];
  oldClientBookingChannels: ClientBookingChannelsDTO["data"],
  clientEmployersChanges: ClientEmployerChange[],
  appointmentFilterCategories: AppointmentFilterCategory[],
  existingAppointmentFilterCategories: AppointmentFilterCategory[],
}

// use new endpoint to update multiple client settings in a single request
async function updateClient({ data }: UpdateParams<Body>) {
  const {
    id,
    attributes,
    existingAttributes,
    existingContract,
    contract,
    payments,
    oldPayments,
    notificationSettings,
    existingNotificationSettings,
    notificationChannelSettings = [],
    existingNotificationChannelSettings = [],
    notificationTypeSettings = [],
    existingNotificationTypeSettings = [],
    oldValidationRules,
    validationRules,
    clientFunctionValidation,
    oldTerms = [],
    terms = [],
    newIdentifiers = [],
    identifiers = [],
    patientIdentifiers,
    newPatientIdentifiers,
    appointmentFilters,
    oldAppointmentFilters,
    templateIds,
    existingTemplateIds,
    oldFollowUpActions = [],
    followUpActions = [],
    oldInstructions = [],
    instructions = [],
    oldAssignedPMS = [],
    assignedPMS = [],
    oldMaintenanceWindows = [],
    maintenanceWindows = [],
    masterClientGroup,
    oldMasterClientGroup,
    notify,
    employerSettings,
    oldEmployerSettings,
    clientBookingChannels,
    oldClientBookingChannels,
    clientClinicianPools = [],
    oldClientClinicianPools = [],
    clientEmployersChanges = [],
    appointmentFilterCategories = [],
    existingAppointmentFilterCategories = []

  } = data as Body;

  await updateMasterClient({
    clientId: id,
    oldMasterClientGroup,
    masterClientGroup,
    handleError: notify,
  });

  await updateClientAttributes({
    clientId: id,
    attributes,
    existingAttributes,
  });

  await updateContracts({
    clientId: id,
    oldContract: existingContract,
    updatedContract: contract,
  });

  await updateAppointmentFilters({
    clientId: id,
    appointmentFilters,
    oldAppointmentFilters,
    appointmentFilterCategories,
    existingAppointmentFilterCategories
  });

  await updateClientTerms({
    clientId: id,
    terms,
    oldTerms,
  });

  await updateFollowUpActions({
    clientId: id,
    followUpActions,
    oldFollowUpActions,
  });

  /* CLIENT CLINICAL POOLS */
  await updateClientClinicalPools({
    clientId: id,
    clientClinicianPools,
    oldClientClinicianPools
  });

  /* PAYMENTS */
  await updatePayments({
    clientId: id,
    payments,
    oldPayments,
  });

  /* NOTIFICATIONS */
  await updateNotificationSettings({
    clientId: id,
    notificationSettings,
    existingNotificationSettings,
  });

  await updateNotificationChannelSettings({
    clientId: id,
    notificationChannelSettings,
    existingNotificationChannelSettings,
  });

  await updateNotificationTypeSettings({
    clientId: id,
    notificationTypeSettings,
    existingNotificationTypeSettings,
  });

  await updateSendGridIds({
    clientId: id,
    templateIds,
    existingTemplateIds,
  });

  /* VALIDATION */
  await updateValidationRules({
    clientId: id,
    oldValidationRules,
    validationRules,
  });

  /* SERVICE CONFIG */
  await updateMaintenanceWindows({
    clientId: id,
    oldMaintenanceWindows,
    maintenanceWindows,
  });

  const { clientFunction, clientIdentifier, multipoint } = validationRules;

  await updateIdentifiers({
    clientId: id,
    identifiers,
    newIdentifiers,
    isEnabled: clientIdentifier.isEnabled,
  });

  await updatePatientIdentifiers({
    clientId: id,
    patientIdentifiers,
    newPatientIdentifiers,
    isEnabled: multipoint.isEnabled,
  });

  await updateExternalValidation({
    clientId: id,
    clientFunctionValidation,
    isEnabled: clientFunction.isEnabled,
  });

  /* INSTRUCTIONS */
  await updateInstructions({
    clientId: id,
    instructions,
    oldInstructions,
  });

  /* ASSIGNED PMS */
  await updateAssignedPMS({
    clientId: id,
    oldAssignedPMS,
    assignedPMS: assignedPMS,
  });

  await updateEmployerSettings({
    clientId: id,
    employerSettings,
    oldEmployerSettings,
  });

  /* CLIENT EMPLOYERS */
  await updateManyEmployers({
    clientEmployersChanges
  });

  await updateClientBookingChannels({
    clientId: id,
    oldClientBookingChannels,
    clientBookingChannels
  })

  return Promise.resolve({
    data,
  });
}

export default updateClient;
