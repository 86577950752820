import React from "react";
import { RaRecord, SimpleList, useCreate, useNotify, TextField } from "react-admin";
import { RecordContextProvider, useGetOne, useRecordContext, useRefresh } from "ra-core";
import { ArrayField, Button, FunctionField, Labeled, SelectInput } from "ra-ui-materialui";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import get from "lodash/get";
import { splitByCapitalLetters } from "../../../../utilities/splitByCapitalLetters";
import { Heading } from "../../../ui/Heading";
import { Group } from "../../../layout/Group";
import { Stack } from "../../../layout/Stack";
import { useEditStateContext } from "../../../../context/EditStateContext";
import {
  identityVerificationResetStatuses,
  identityVerificationResetStatusesChoices
} from "../../../../constants/constants";


export function IdentityVerificationDetails() {
  const record = useRecordContext();
  const { currentState } = useEditStateContext();
  const kycEnabled = isKycEnabled(record);


  const isDependant = get(record, "attributes.isDependant");

  if (!kycEnabled || isDependant) {
    return null;
  }

  if (currentState === "show") return <ShowContent />;

  return <EditContent />;
}


function EditContent() {
  const record = useRecordContext();
  const idVerificationStatus = get(record, "attributes.prevIdentityVerificationStatus");

  return (
    <Group heading={<Heading level={2}>Identity Verification</Heading>}>
      <Stack>
        <SelectInput
          data-testid="edit-id-verification-status"
          choices={identityVerificationResetStatusesChoices(idVerificationStatus)}
          source="attributes.identityVerificationStatus"
          label="ID Verification Status"
          required
          disabled={!identityVerificationResetStatuses.includes(idVerificationStatus)}
          helperText={false}
        />
      </Stack>
    </Group>
  );
}


function ShowContent() {
  const record = useRecordContext();
  const idVerificationStatus = get(record, "attributes.identityVerificationStatus");
  const shouldShowResendButton = showResendButton(idVerificationStatus);
  const kycFailed = idVerificationStatus === "Failed" || idVerificationStatus === "Blocked";

  return (
    <Group data-testid={"id-verification-details"} heading={<Heading level={2}>Identity Verification</Heading>}>
      <Stack>
        <Labeled label="ID Verification Status">
          <Typography data-testid="id-verification-status">
            {splitByCapitalLetters(idVerificationStatus)}
          </Typography>
        </Labeled>
        {shouldShowResendButton && <ResendKYCButton />}
        {kycFailed && <VerificationFailedReason />}
        {kycFailed && <IdentityVerificationPatientDetails />}
      </Stack>
    </Group>);
}

const isKycEnabled = (record: RaRecord) => {
  return record?.clients[0].attributes?.isKycEnabled;
};

const showResendButton = (idStatus: any) => {
  return ["NotStarted", "Failed", "Expired"].includes(idStatus);
};

const ResendKYCButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const { data: client } = useGetOne("clients", { id: record?.clients[0]?.id });

  const postVerifyBaseUrl = client?.existingContract?.attributes?.domain;

  const params = {
    postVerifyBaseUrl,
    userId: record.users?.[0]?.id,
    clientId: record.clients[0].id
  };

  const notify = useNotify();

  const [resendKycLink, { isLoading }] = useCreate();

  const submit = () =>
    resendKycLink(
      "resendVerificationLink",
      {
        data: params
      },
      {
        onSuccess: () => {
          notify("Verification link was successfully send");
          refresh();

        },
        onError: () => {
          notify("There was a problem resending a verification link");
        }
      });

  return (<Button
    disabled={isLoading || !postVerifyBaseUrl}
    label={"Resend Verification Link"}
    aria-label={"Resend Verification Link"}
    color="primary"
    variant="outlined"
    onClick={submit}
  />);
};


const VerificationFailedReason = () => {
  const record = useRecordContext();

  const failedSessions = record.identityVerificationSessions
    .filter((x: any) => x.attributes.identityVerificationStatus === "Failed");

  const reasons = failedSessions[0]?.identityVerificationSessionChecks
    .filter((x: any) => x.attributes.checkResult === "REJECT" && x.attributes.checkRecommendation)
    .map((x: any) => ({ value: x.attributes.checkRecommendation }));

  if (!reasons || reasons.length === 0) {
    return null;
  }

  return (
    <Labeled label={"ID Verification Failure Reasons"}>
      <RecordContextProvider value={{ reasons }}>
        <ArrayField source="reasons">
          <SimpleList
            sx={{listStyle: "disc"}}
            rowStyle={rec => ({ display: "list-item", marginLeft: "15px" })}
            linkType={false}
            primaryText={rec => `${rec.value}`}
          />
        </ArrayField>
      </RecordContextProvider>
    </Labeled>
  );
};

const IdentityVerificationPatientDetails = () => {
  const record = useRecordContext();
  const patient = record.identityVerificationPatients[0];

  if (!patient) {
    return (
      <>
        <Heading level={4}>ID Details</Heading>
        <Typography>
          {"No ID details found for the patient"}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Heading level={4}>ID Details</Heading>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Labeled label={"First Name"}>
            <TextField source={"identityVerificationPatients.0.attributes.firstName"} />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Labeled label={"Last Name"}>
            <TextField source={"identityVerificationPatients.0.attributes.lastName"} />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Labeled label={"Full Name"}>
            <TextField source={"identityVerificationPatients.0.attributes.fullName"} />
          </Labeled>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Labeled label={"Date Of Birth"}>
            <FunctionField
              render={(rec: any) => dayjs(rec.identityVerificationPatients[0].attributes.dateOfBirth).format("DD/MM/YYYY")} />
          </Labeled>
        </Grid>
      </Grid>
    </>
  );

};