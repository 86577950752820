import { getAll } from "..";
import Papa from "papaparse";

export interface IndentiferDownloadBody {
  id: string;
}

interface Identifier {
  type: string;
  id: string;
  attributes: {
    clientIdentifier: string;
    isEnabled: boolean;
    name?: string;
  };
}

async function createIdentifiersDownload(body: IndentiferDownloadBody) {
  const { id } = body;

  const { data } = await getAll(`/ms/clients/${id}/identifiers?page[size]=100`);

  const identifiers: Identifier[] = Object.values(data);

  const formattedIdentifiers = identifiers
    .filter(el => el.attributes.isEnabled)
    .map(el => {
      return [el.attributes.clientIdentifier, el.attributes.name];
    });

  const csv = Papa.unparse(formattedIdentifiers);

  const fileName = "clientIdentifiers";
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return {
    data: {
      id: "id-to-satisfy-ra",
    },
  };
}

export default createIdentifiersDownload;
