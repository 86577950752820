import React from "react";
import { useRecordContext } from "react-admin";
import { FieldValues, useFormContext } from "react-hook-form";
import { BaseEmployerDialog } from "./BaseEmployerDialog";

export const EmployerCreateDialog = (props: {
  isOpened: boolean;
  onClose: () => void;
  addEmployer: (values: FieldValues) => void;
}) => {
  const record = useRecordContext();
  const { trigger } = useFormContext();

  const { onClose, isOpened, addEmployer } = props;

  if (!record) {
    return null;
  }

  function submit(values: FieldValues) {
    addEmployer(values);

    trigger("employerSettings.attributes.employerRequiredOnBookings");
    onClose();
  }

  return (
    <BaseEmployerDialog
      isOpened={isOpened}
      onClose={onClose}
      title={"Create employer"}
      submitButtonText={"Add"}
      record={{ name: "", code: "" }}
      submit={submit}
    />
  );
};
