import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Form, maxLength, required } from "react-admin";
import { TextInput } from "ra-ui-materialui";
import { FieldValues } from "react-hook-form";

interface EmployerRecord {
  name: string;
  code: string;
}

export const BaseEmployerDialog = (props: {
  isOpened: boolean;
  onClose: () => void;
  title: string;
  submitButtonText: string;
  record: EmployerRecord;
  submit: (values: FieldValues) => void;
}) => {
  const { isOpened, onClose, title, submitButtonText, record, submit } = props;

  return (
    <Dialog
      open={isOpened}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      data-cy="employer-dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <Form record={record} onSubmit={submit}>
        <DialogContent>
          <TextInput
            label={"Name"}
            source="name"
            fullWidth
            validate={[required(), maxLength(512)]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} aria-label="Cancel">
            Cancel
          </Button>
          <Button type="submit" variant="contained" data-testid={"submitChangeEmployerButton"}>
            {submitButtonText}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
