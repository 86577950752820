import dayjs from "dayjs";
import {
  required,
  useNotify,
  useRedirect,
  RaRecord,
  minValue,
  maxValue,
} from "ra-core";
import {
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from "ra-ui-materialui";
import React from "react";
import { consultationTypeChoices } from "../../constants/constants";
import getCurrentDate from "../../utilities/getCurrentDate";
import { validDate } from "../../validators/validDate";
import { DateTimeInput } from "../inputs/DateTimeInput";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { Heading } from "../ui/Heading";

export function AppointmentCreate() {
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Create
      title="Create Appointment"
      mutationOptions={{
        onSuccess: (newRecord: RaRecord) => {
          notify("Appointment was created successfully");
          redirect(`/adminAppointments/${newRecord.id}/show`);
        },
      }}
      transform={(record: RaRecord) => {
        const defaultDate = getCurrentDate();
        const { duration, attributes } = record;
        const { start = defaultDate } = attributes;
        const end = dayjs(start).add(duration, "minutes").format();

        record.attributes.end = end;

        return record;
      }}
    >
      <SimpleForm warnWhenUnsavedChanges>
        <Heading level={2}>Create Appointment</Heading>
        <ReferenceInput
          source="relationships.clinicalPractitioner.data.id"
          reference="clinicalPractitioners"
          validate={required("A CP is required")}
        >
          <AutocompleteInput
            showClearButton
            label="Clinical Practitioner"
            placeholder="Search by CP name..."
            source="id"
            optionText="attributes.fullName"
            filterToQuery={query => ({
              fullName: query,
            })}
          />
        </ReferenceInput>
        <ReferenceInput
          reference="clinicalServices"
          source="attributes.clinicalServiceId"
          filter={{ deliveryType: "Synchronous" }}
        >
          <AutocompleteInput
            showClearButton
            label="Clinical Service"
            source="id"
            optionText="attributes.name"
            filterToQuery={query => ({
              name: query
            })}
          />
        </ReferenceInput>
        <DateTimeInput
          label="Start time"
          source="attributes.start"
          validate={[
            required("A start time is required"),
            validDate("Please enter a valid date"),
          ]}
          hasHelperText
        />
        <NumberInput
          label="Duration"
          source="duration"
          validate={[
            required("Please choose an appointment duration"),
            minValue(5, "An appointment must be at least 5 minutes long"),
            maxValue(60, "An appointment cannot be longer than 60 minutes"),
          ]}
        />
        <SelectInput
          validate={required("A consultation is required")}
          label="Consultation Type"
          source="attributes.consultationType"
          choices={consultationTypeChoices}
        />
      </SimpleForm>
    </Create>
  );
}
