import {
  ClinicalPractitionerDocuments,
  AdminClinicalPractitionerDTO,
} from "../../types/clinicalPractitioners";
import createResourceRules from "./createResourceRules";
import uploadClinicalPractitionerDocuments from "./uploadClinicalPractitionerDocuments";
import { create } from "../gaiaClient";
import updateRegistrations from "./updateRegistrations";
import { CPRegistrations } from "./helpers/formatClinicalPractitioner";

type CreateCPAttributes = Omit<
  AdminClinicalPractitionerDTO["data"]["attributes"],
  "password" | "userName"
>;

interface Body {
  attributes: CreateCPAttributes;
  expertiseAreas: string[];
  resourceManagementRules: {
    attributes?: {
      appointmentsPerHour: number;
      appointmentDuration: number;
    };
  }[];
  cpDocuments: ClinicalPractitionerDocuments[];
  cpRegistrations: CPRegistrations;
}

async function createClinicalPractitioner(body: Body) {
  const createCPEndpoint = `/ms/clinicalPractitioners?include=expertiseAreas`;
  const {
    attributes,
    expertiseAreas,
    resourceManagementRules = [{}],
    cpDocuments = [],
  } = body;

  const userName = attributes.email;

  const createCPBody: AdminClinicalPractitionerDTO = {
    data: {
      type: "adminClinicalPractitioners",
      attributes: {
        ...attributes,
        password: null,
        userName,
      },
      relationships: {
        expertiseAreas: {
          data: expertiseAreas.map(area => ({
            id: area,
            type: "expertiseAreas",
          })),
        },
      },
    },
  };

  const { data } = await create(createCPEndpoint, createCPBody, undefined, true);

  const promises = [];

  if (cpDocuments.length > 0) {
    const updateDocumentsBody = {
      documents: cpDocuments,
      userId: data.attributes.identityUserId,
      id: data.id,
    };

    promises.push(uploadClinicalPractitionerDocuments(updateDocumentsBody));
  }

  const resourceRulesArgs = {
    clinicalPractitionerId: data.id,
    ...resourceManagementRules[0].attributes,
  };

  promises.push(createResourceRules(resourceRulesArgs));
  promises.push(updateRegistrations(data.id, [], body.cpRegistrations));

  //@ts-ignore
  await Promise.all(promises);

  return { data };
}

export default createClinicalPractitioner;
