import { 
  FormDataConsumer, 
  required
} from "ra-core";
import {
  BooleanInput,
  CheckboxGroupInput,
  SelectInput,
  TextInput,
} from "ra-ui-materialui";
import React, { useMemo } from "react";
import { ReferenceInput } from "react-admin";
import {
  CLIENT_DISCARDED_ID,
  CLIENT_LIVE_ID,
  consultationFollowUpActionChoices,
  contractQualificationChoices,
  instructionTypeChoices,
  specialtyChoices,
  termsTypeChoices,
} from "../../../constants/constants";
import { EditTabProps } from "../../../types/utilities";
import getCurrentDate from "../../../utilities/getCurrentDate";
import TooltipAdornment from "../../booking/TooltipAdornment";
import { AdornedField } from "../../fields/AdornedField";
import { FormIteratorAddButton } from "../../form/FormIteratorAddButton";
import { FormIteratorRemoveButton } from "../../form/FormIteratorRemoveButton";
import { DateInput } from "../../inputs/DateInput";
import { MarkdownInput } from "../../inputs/MarkdownInput";
import { FormTab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { CreateMasterClient } from "../CreateMasterClient";
import { minDate as validateMinDate } from "../../../validators/minDate";
import { ArrayInput } from "../../inputs/ArrayInput";
import { SimpleFormIterator } from "../../form/SimpleFormIterator";
import AutocompleteInput from "../../inputs/AutocompleteInput";
import { FeatureFlagService, useFlag } from "../../../featureFlags";
import { Qualifications } from "../Qualifications";
import { Group } from "../../layout/Group";
import { textInputAdornmentStyles } from "../../../theme";
import { Stack } from "../../layout/Stack";
import { BookingChannels } from "../BookingChannels";
import { TOGGLE_KYC, TOGGLE_OTP } from "../../../featureFlags/flags";
import { countrySelectChoices } from "../../../data/countryCodes";
import { nonEmpty } from "../../../validators/nonEmpty";

const groupStyles = {
  background: "none",
  padding: 0,
  mt: 2,
};

const checkboxGroupInputStyles = {
  width: "550px",
  display: "flex",
  "& label": {
    width: "250px",
    "& > span:first-of-type": {
      width: "50px",
    },
  },
};

const arrayInputStyles = {
  "& .RaSimpleFormIterator-line": {
    alignItems: "self-start",
    "& .RaSimpleFormIterator-action": {
      mt: 2,
    },
  },
};

export function GeneralEditTab(props: EditTabProps) {
  const defaultMinDate = useMemo(() => getCurrentDate(), []);

  const qualificationChoices = () => {
    return contractQualificationChoices.filter(
      qualification =>
        qualification.id !== "gmcgpRequired" &&
        qualification.id !== "imcgpRequired"
    );
  };

  const isKycEnabled = useFlag(TOGGLE_KYC);
  const isOtpEnabled = useFlag(TOGGLE_OTP);

  return (
    <FormTab {...props} label="General">
      <Heading level={2}>Client Details</Heading>
      <TextInput label="Contract Name" source="attributes.name" required />

      <BooleanInput
        label="Is Discarded"
        format={clientStatusID => clientStatusID === CLIENT_DISCARDED_ID}
        source="attributes.clientStatusID"
        parse={isDiscarded => {
          const status = isDiscarded ? CLIENT_DISCARDED_ID : CLIENT_LIVE_ID;
          return status;
        }}
      />

      <SelectInput
        source="attributes.countryCode"
        label="Country"
        choices={countrySelectChoices}
        validate={nonEmpty("All contracts require a country")}
      />

      <ReferenceInput
        source="masterClientGroup.clientGroups[0].id"
        reference="clientGroups"
        sx={{ flexGrow: 0 }}
      >
        <AdornedField
          filterToQuery={(query: string) => ({
            name: query,
          })}
          Field={AutocompleteInput}
          createLabel="Create new master client"
          createItemLabel="Create new master client"
          create={<CreateMasterClient />}
          label="Master Client"
          optionText="name"
          placeholder="Search by Master Client name..."
          adornment={
            <TooltipAdornment
              sx={textInputAdornmentStyles}
              text="Master client is a reporting value - select this to link multiple clients"
            />
          }
        />
      </ReferenceInput>
      
      { isKycEnabled &&
        <BooleanInput
          label="Is KYC Enabled"
          source="attributes.isKycEnabled"
          helperText=""
          data-testid="is-kyc-enabled"
        />
      }

      { isOtpEnabled &&
        <BooleanInput
          label="Is OTP Enabled"
          source="attributes.isOtpEnabled"
          helperText=""
          data-testid="is-otp-enabled"
        />
      }

      <Qualifications />

      <BookingChannels/>

      <CheckboxGroupInput
        sx={checkboxGroupInputStyles}
        label="Required Qualifications"
        source="contract.attributes.requiredQualifications"
        choices={qualificationChoices()}
      />

      <ArrayInput
        source="followUpActions"
        label="Required Follow Up Actions"
        level={2}
        sx={arrayInputStyles}
      >
        <SimpleFormIterator
          removeButton={<FormIteratorRemoveButton label="Delete action" />}
          addButton={<FormIteratorAddButton label="Add action" />}
        >
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              if (!getSource) return null;

              const isDisabled = scopedFormData?.id;

              return (
                <Group sx={groupStyles}>
                  <Stack>
                    <SelectInput
                      label="Specialty"
                      source={getSource("attributes.specialty")}
                      choices={specialtyChoices}
                      validate={required("Please select a specialty")}
                      disabled={isDisabled}
                    />
                    <SelectInput
                      label="Follow Up Actions"
                      source={getSource("attributes.followUpActionType")}
                      choices={consultationFollowUpActionChoices}
                      validate={required(
                        "Please select a follow up action type"
                      )}
                      disabled={isDisabled}
                    />
                  </Stack>
                </Group>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="terms" label="Terms" level={2} sx={arrayInputStyles}>
        <SimpleFormIterator
          removeButton={<FormIteratorRemoveButton label="Delete term" />}
          addButton={<FormIteratorAddButton label="Add term" />}
        >
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              if (!getSource) return null;

              const isDisabled = Boolean(scopedFormData?.id);

              return (
                <Group sx={groupStyles}>
                  <Stack>
                    <TextInput
                      source={getSource("attributes.title")}
                      label="Title"
                      disabled={isDisabled}
                      required
                    />
                    <TextInput
                      source={getSource("attributes.version")}
                      label="Version"
                      disabled={isDisabled}
                      required
                    />
                    <MarkdownInput
                      validationMessages={{
                        emptyMessage: "Terms and Conditions can't be empty",
                      }}
                      label="Body"
                      disabled={isDisabled}
                      source={getSource("attributes.body")}
                      required
                    />
                    <SelectInput
                      source={getSource("attributes.type")}
                      label="Type"
                      disabled={isDisabled}
                      required
                      choices={termsTypeChoices}
                    />
                    <DateInput
                      disabled={isDisabled}
                      minDate={!isDisabled ? defaultMinDate : ""}
                      validate={
                        !isDisabled ? validateMinDate(defaultMinDate) : []
                      }
                      defaultValue={defaultMinDate}
                      source={getSource("attributes.activeFrom")}
                      label="Active From"
                    />
                  </Stack>
                </Group>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <Instructions />
    </FormTab>
  );
}

function Instructions() {
  const shouldHideInstructions = FeatureFlagService.getInstance().getFlag(
    "ed-3-hide-client-instructions"
  );

  if (shouldHideInstructions) {
    return null;
  }

  return (
    <ArrayInput
      source="instructions"
      label="Instructions"
      level={2}
      sx={arrayInputStyles}
    >
      <SimpleFormIterator
        removeButton={<FormIteratorRemoveButton label="Remove instruction" />}
        addButton={<FormIteratorAddButton label="Add instruction" />}
      >
        <BooleanInput source="attributes.isEnabled" label="Is Enabled" />
        <SelectInput
          source="attributes.instructionType"
          label="Type"
          choices={instructionTypeChoices}
        />
        <MarkdownInput
          validationMessages={{
            emptyMessage: "Instructions can't be empty",
          }}
          source="attributes.instructions"
          label="Instructions"
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
}
