import React, { useEffect, useState } from "react";
import { Datagrid, TextField } from "ra-ui-materialui";
import { ListContextProvider, TextInput, useGetList, useList } from "react-admin";
import debounce from "lodash/debounce";
import { CreateEmployerButtonField } from "../fields/CreateEmployerButtonField";
import { EmployersEditBulkActionButtons } from "../buttons/EmployersEditBulkActionButtons";
import { EditEmployerButtonField } from "../fields/EditEmployerButtonField";
import { NoEmployersMessage } from "../messages/NoEmployersMessage";
import { Box, Button } from "@mui/material";
import { FieldValues, useFormContext, useWatch } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { ChangeAction, ClientEmployerChange, ClientEmployerDTO } from "../../../types/clientEmployer";
import sortBy from "lodash/sortBy";
import { EditEmployersChangesList } from "../EditEmployersChangesList";

const clientEmployersChangesSource = "clientEmployersChanges";

export function EmployerTableInput() {
  const perPage = 25;
  const { setValue, formState } = useFormContext();
  const clientId = useWatch({name: "id"});
  const [shouldReplaceResponse, setShouldReplaceResponse] = useState(false);
  const [changesList, setChangesList] = useState<ClientEmployerChange[]>([]);
  const [listOfEmployers, setListOfEmployers] = useState<ClientEmployerDTO["data"][]>([]);
  const [filterState, setFilterState] = useState({ page: 1, name: "" });
  const sortSelector = (rec: ClientEmployerDTO["data"]) => rec.attributes.name.toLowerCase();

  const mapEmployersListWithUpdatedName = (id: string, name: string) => {
    return listOfEmployers.map((empl: ClientEmployerDTO["data"]) => {
      if (empl.id !== id) {
        return empl;
      }

      return {
        ...empl,
        attributes: {
          ...empl.attributes,
          name: name
        }
      };
    });
  };

  const {isLoading, pageInfo } = useGetList(
    "employers",
    {
      pagination: { page: filterState.page, perPage: perPage },
      filter: { "clientId": clientId, name: filterState.name },
      sort: { field: "name", order: "ASC" }
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        // exclude deleted employers
        const deletedIds = changesList
          .filter(x => x.action === ChangeAction.Deleted)
          .map(x => x.id);

        const dataToSet = data?.filter(r => !deletedIds.includes(r.id));

        // set updated employers
        const updatedIds = changesList
          .filter(x => x.action === ChangeAction.Edited)
          .map(x => x.id);

        dataToSet!.forEach(rec => {
          if (updatedIds.includes(rec.id)) {
            rec.attributes.name = changesList.find(x => x.id === rec.id)!.payload.attributes.name;
          }
        });

        if (shouldReplaceResponse) {
          setListOfEmployers(dataToSet!);
        } else {
          setListOfEmployers([...listOfEmployers, ...dataToSet!]);
        }
      }
    }
  );

  useEffect(() => {
    setChanges([]);
    setFilterState({page: 1, name: ''})
    setShouldReplaceResponse(true);

  }, [formState.isSubmitSuccessful]);

  const listContext = useList<ClientEmployerDTO["data"]>({ data: listOfEmployers });

  const setChanges = (updatedList: ClientEmployerChange[]) => {
    setChangesList(updatedList);
    setValue(clientEmployersChangesSource, updatedList, { shouldDirty: true });
  };

  const loadMoreItems = () => {
    if (!isLoading) {
      setFilterState({ ...filterState, page: filterState.page + 1 });
      setShouldReplaceResponse(false);
    }
  };

  const addEmployer = (values: FieldValues) => {
    const newEmployer = {
      id: uuid(),
      attributes: {
        name: values.name,
        code: values.code,
        clientId
      }
    };

    setChanges([...changesList, { id: newEmployer.id, action: ChangeAction.Added, payload: newEmployer }]);
  };

  const updateEmployer = (id: string, values: FieldValues) => {
    const employer = changesList.find(r => r.id === id)?.payload ??
      listOfEmployers.find(r => r.id === id)!;

    const oldName = employer.attributes.name;

    if (oldName === values.name) {
      return;
    }

    employer!.attributes.name = values.name;

    const updatedList = mapEmployersListWithUpdatedName(id, values.name);

    setListOfEmployers(updatedList);

    const alreadyChanged = changesList.find(r => r.payload.id === id);

    if (!alreadyChanged) {
      setChanges([...changesList, { id, action: ChangeAction.Edited, payload: employer, oldName }]);
    } else if (alreadyChanged.oldName === values.name) {
      // removes from the changes list if edited and initial values are the same
      setChanges(changesList.filter(r => r.id !== id));
    } else {
      setChanges([...changesList]);
    }
    setListOfEmployers([...updatedList]);
  };

  const deleteEmployers = () => {
    const deleted = listOfEmployers.filter(e => listContext.selectedIds.includes(e.id));

    let updatedList = changesList;

    deleted.forEach(e => {
      const changed = updatedList.find(r => r.id === e.id);

      if (changed) {
        updatedList = updatedList.filter(r => r !== changed);
      }

      updatedList.push({ id: e.id, action: ChangeAction.Deleted, payload: e });

    });

    setChanges([...updatedList]);
    setListOfEmployers([...listOfEmployers.filter(e => !listContext.selectedIds.includes(e.id))]);
  };

  const onFilterChange = debounce((e: any) => {
    setShouldReplaceResponse(true);
    setFilterState({ page: 1, name: e.target.value });
  }, 600);

  const onUndoClicked = (change: ClientEmployerChange) => {
    if (change.action === ChangeAction.Edited) {
      const updatedList = mapEmployersListWithUpdatedName(change.id, change.oldName!);
      setListOfEmployers(updatedList);
    } else if (change.action === ChangeAction.Deleted) {
      const updatedList = sortBy([...listOfEmployers, change.payload], [sortSelector]);
      setListOfEmployers(updatedList);
    }
    setChanges(changesList.filter(r => r.id !== change.id));
  };

  const onResetChangesClicked = () => {
    let updatedList = listOfEmployers;

    changesList.forEach((change: ClientEmployerChange) => {
      if (change.action === ChangeAction.Edited) {
        updatedList = mapEmployersListWithUpdatedName(change.id, change.oldName!);
      } else if (change.action === ChangeAction.Deleted) {
        updatedList.push(change.payload);
      }
    });

    updatedList = sortBy(updatedList, [sortSelector]);

    setListOfEmployers(updatedList);
    setChanges([]);
  };

  const employerNameFilterStyles = {
    "& .MuiFormHelperText-root": {
      display: "none"
    }
  };

  const styles = {
    "& .RaBulkActionsToolbar-toolbar": {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "& .RaDatagrid-row td:last-child": {
      textAlign: "right"
    }
  };

  return (
    <>
      <TextInput
        data-testid={"employerNameFilter"}
        onChange={onFilterChange}
        label={"Employer name filter"}
        source={"nameFilter"}
        name={"employerNameFilter"}
        value={filterState.name}
        sx={employerNameFilterStyles}
      />
      <Box sx={{ marginTop: "3rem", maxHeight: "30rem", overflow: "auto" }}>
        <ListContextProvider value={listContext}>
          <Datagrid
            bulkActionButtons={<EmployersEditBulkActionButtons deleteEmployers={deleteEmployers} />}
            sx={styles}
            data-cy="employer-edit-table"
            empty={<NoEmployersMessage isEditPage={true} />}
          >
            <TextField label="Name" source="attributes.name" data-testid={"employerRecordName"} />
            <EditEmployerButtonField updateEmployer={updateEmployer} />
          </Datagrid>
          <Box display="flex" justifyContent="center">
            <Button
              data-testid={"LoadMoreButton"}
              sx={{ marginTop: "10px" }}
              onClick={loadMoreItems}
              variant="outlined"
              size="large"
              disabled={isLoading || !pageInfo?.hasNextPage}
            >
              Load more
            </Button>
          </Box>
        </ListContextProvider>
      </Box>
      <EditEmployersChangesList onResetChangesClicked={onResetChangesClicked} onUndoClicked={onUndoClicked} data={changesList} />
      <CreateEmployerButtonField addEmployer={addEmployer} />
    </>
  );
}

