import React from "react";
import { useRecordContext } from "react-admin";
import { FieldValues } from "react-hook-form";
import { BaseEmployerDialog } from "./BaseEmployerDialog";


export const EmployerEditDialog = (props: {
  isOpened: boolean;
  onClose: () => void;
  updateEmployer: (id: any, values: FieldValues) => void;
}) => {
  const record = useRecordContext();

  const { onClose, isOpened, updateEmployer } = props;

  if (!record) {
    return null;
  }

  function submit(values: FieldValues) {
    updateEmployer(record.id, values);
    onClose();
  }

  return (
    <BaseEmployerDialog
      isOpened={isOpened}
      onClose={onClose}
      title={"Edit employer"}
      submitButtonText={"Edit"}
      record={{ name: record.attributes.name, code: record.attributes.code }}
      submit={submit}
    />
  );
};
