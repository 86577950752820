import React from "react";
import { Datagrid, TextField } from "ra-ui-materialui";
import { BooleanField } from "../../fields/BooleanField";
import { ShowTabProps } from "../../../types/utilities";
import { Tab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { NoEmployersMessage } from "../messages/NoEmployersMessage";
import { Pagination, ReferenceManyField } from "react-admin";

export function EmployersShowTab(props: ShowTabProps) {
  return (
    <Tab {...props} label="Employers">
      <Heading level={2}>Employers Configuration</Heading>
      <BooleanField
            label="Should capture employer"
            source="employerSettings.attributes.employerRequiredOnBookings"
            emptyText="No"
          />
      <BooleanField
            label="Allow 'other' option"
            source="employerSettings.attributes.allowOtherEmployeeOption"
            emptyText="No"
          />      

      <ReferenceManyField
        reference="clientEmployers"
        target="client.Id"
        label={false}
        pagination={<Pagination />}
        sort={{field: "name", order: "ASC"}}
      >
        <Datagrid
          bulkActionButtons={false}
          empty={<NoEmployersMessage isEditPage={false} />}
        >
          <TextField label="Name" source="attributes.name" />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
  );
}
