import dayjs, { Dayjs } from "dayjs";
import { AppointmentStatus } from "../types/appointments";
import { Specialty } from "../types/clinicalPractitioners";
import { Gender } from "../types/common";
import { ConsultationType } from "../types/consultations";

export type SchedulerFilter = {
  patientName?: string;
  consultationType?: ConsultationType;
  bookingConsultationType?: ConsultationType;
  specialties?: Specialty;
  startTime: Dayjs;
  endTime: Dayjs;
  resourcePoolId?: string;
  clinicalPractitionerIds?: string[];
  gender?: Gender;
  patientDateOfBirth?: string;
  clientId?: string;
  status?: AppointmentStatus;
  gMC?: boolean;
  iMC?: boolean;
  date: Date;
};

const defaultStartTime = dayjs()
  .hour(0)
  .minute(0)
  .second(0)
  .millisecond(0)
  .toISOString();

const defaultEndTime = dayjs()
  .hour(23)
  .minute(59)
  .second(0)
  .millisecond(0)
  .toISOString();

export const defaultSchedulerFilters = {
  startTime: defaultStartTime,
  endTime: defaultEndTime,
};
