import { create } from "..";

interface Body {
  attributes: {
    name: string;
    code: string;
    clientId: string;
  };
}

async function createEmployer(body: Body) {
  const createEmployerEndpoint = "ms/employers";
  const { attributes } = body;
  const createEmployerBody = {
    data: {
      type: "employers",
      attributes: attributes,
    },
  };

  const employerRes = await create(createEmployerEndpoint, createEmployerBody);

  return employerRes;
}

export default createEmployer;
