import React from "react";
import {
  Filter,
  FilterProps,
  SelectInput,
  TextInput,
  BooleanInput,
  DateInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  useListFilterContext,
} from "react-admin";

import {
  consultationTypeChoices,
  specialtyChoices,
  appointmentStatusChoices,
  genderChoices,
} from "../../constants/constants";
import { TimeInput } from "../inputs/TimeInput";
import { minTime } from "../../validators/minDate";
import { maxTime } from "../../validators/maxDate";
import dayjs, { Dayjs } from "dayjs";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { SchedulerFilter } from "../../context/schedulerFilter";

const helperTextOffsetStyles = {
  marginBottom: 0.5,
};

type Props = Omit<FilterProps, "children"> & {
  filterValues?: SchedulerFilter;
};

export function ScheduleFilter(props: Props) {
  const { filterValues } = useListFilterContext();
  const { startTime, endTime } = filterValues;

  return (
    <Filter {...props}>
      <TextInput alwaysOn label="Patient Name" source="patientName" />

      <ReferenceArrayInput
        alwaysOn
        source="clinicalPractitionerIds"
        reference="clinicalPractitioners"
        allowEmpty={false}
      >
        <AutocompleteArrayInput
          filterToQuery={(query: string) => ({ fullName: query })}
          label="Clinician"
          source="id"
          shouldRenderSuggestions={(query: string) => query.length > 1}
          helperText={false}
          optionText="attributes.fullName"
        />
      </ReferenceArrayInput>
      <ReferenceInput
        alwaysOn
        source="resourcePoolId"
        reference="resourcePools"
      >
        <AutocompleteInput
          label="Resource Pool"
          filterToQuery={(query: string) => ({ name: query })}
          source="id"
          helperText={false}
          optionText="attributes.name"
        />
      </ReferenceInput>
      <SelectInput
        source="consultationType"
        label="Booking Type"
        choices={consultationTypeChoices}
        alwaysOn
        emptyText="No Preference"
      />
      <SelectInput
        source="specialties"
        label="Specialty"
        choices={specialtyChoices}
        alwaysOn
        emptyText="No Preference"
      />
      <TimeInput
        sx={helperTextOffsetStyles}
        alwaysOn
        parse={filterParse}
        label="Start Time"
        source="startTime"
        validate={[filterMaxTime(endTime)]}
      />

      <TimeInput
        alwaysOn
        sx={helperTextOffsetStyles}
        label="End Time"
        parse={filterParse}
        source="endTime"
        validate={[
          filterMinTime(startTime),
          filterMaxTime(dayjs().endOf("day")),
        ]}
      />
      <SelectInput
        source="gender"
        label="CP Gender"
        choices={genderChoices}
        emptyText="No Preference"
      />
      <ReferenceInput
        source="clientId"
        reference="clients"
        sort={{ field: "id", order: "ASC" }}
      >
        <AutocompleteInput
          label="Client Contract Name"
          filterToQuery={(query: string) => ({ name: query })}
          source="id"
          placeholder="Type to search by name..."
          shouldRenderSuggestions={(query: string) => query.length > 1}
          helperText={false}
          optionText="attributes.name"
        />
      </ReferenceInput>
      <SelectInput
        source="status"
        label="Appointment Status"
        choices={appointmentStatusChoices}
        emptyText="No Preference"
      />
      <BooleanInput label="Is GMC Registered" source="gMC" />
      <BooleanInput label="Is IMC Registered" source="iMC" />
      <DateInput label="Date of Birth" source="patientDateOfBirth" />
    </Filter>
  );
}

const defaultTime = dayjs("invalid");

// We have to do this because the filter returns a Date string and not a dayjs object
const filterParse = (val: any) => {
  if (!val) return defaultTime;

  const [hour, minute] = val.split(":");
  const time = dayjs().set("hour", hour).set("minute", minute).format();
  return time;
};

const filterMaxTime = (max: Date | Dayjs) => (date: Date) => {
  return maxTime(dayjs(max))(dayjs(date));
};

const filterMinTime = (min: Date) => (date: Date) => {
  return minTime(dayjs(min))(dayjs(date));
};
