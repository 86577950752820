import * as React from "react";
import { cloneElement } from "react";
import { useListContext, TopToolbar, ListActionsProps } from "react-admin";
import { CreateBookingButton } from "../buttons/CreateBookingButton";
import { CreateButton } from "../CreateButton";
import { ResetListFilterButton } from "../buttons/ResetListFilterButton";
import { defaultPatientFilterValues } from "./PatientList";

function getIsCreateButtonDisabled(filterValues: Record<string, unknown>) {
  return Object.keys(filterValues).length <= 1;
}

export function PatientListActions(props: ListActionsProps) {
  const { className, filters } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();

  const { clients, dateOfBirth, email, firstName, lastName } = filterValues;
  const initialValues = {
    firstName,
    lastName,
    email,
    clientId: clients,
    dateOfBirth,
  };
  const isCreateButtonDisabled = getIsCreateButtonDisabled(filterValues);

  return (
    <TopToolbar className={className}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ResetListFilterButton defaultFilterValues={defaultPatientFilterValues} />
      <CreateButton
        isDisabled={isCreateButtonDisabled}
        initialValues={initialValues}
        label="Create Patient"
        size="medium"
        resource="patientRecords"
      />
    </TopToolbar>
  );
}

export function PatientEditActions() {
  return (
    <TopToolbar>
      <CreateBookingButton />
    </TopToolbar>
  );
}
