export function mapAppointmentFilter(appointmentFilters: any) {


  return  appointmentFilters.attributes.categories?.map((r: any) => {
    const isClinicalService = !!r.clinicalServiceId;
    return ({
      id: isClinicalService ? r.clinicalServiceId.toString() : r.specialty,
      name: isClinicalService ? r.clinicalService?.name : r.specialty,
      type: isClinicalService ? "clinicalService" : "speciality",
      consultationType: r.consultationType,
      deliveryType: r.clinicalService?.deliveryType
    });
  });
}
